.table {
  margin: 20px 0;
}

.table-header {
  display: flex;
  line-height: 24px;
  font-size: 16px;
  padding: 0 10px;
  justify-content: space-between;
}

.table-empty {
  margin: 200px;
  text-align: center;
  font-size: 16px;
}

.table-row {
  display: flex;
  line-height: 24px;
  white-space: nowrap;
  padding: 5px;
  background: #ffffff;
}

.table-header > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.table-row > span {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.button-inline {
  border-width: 0;
  background: transparent;
  color: inherit;
  text-align: inherit;
  -webkit-font-smoothing: inherit;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
}

.button-active {
  border-radius: 0;
  border-bottom: 1px solid #38BB6C;
}

.table td {
  padding: .50rem;
}

tr:nth-child(even) td {
  background: #fafafa;
}

tr:nth-child(odd) td {
  background: #efefef;
}

tr:hover td {
  background: #ffffaa;
  color: #222222;
}

.loading {
  color: #737a7a;
  font-size: smaller;
}

.table .overcommitted {
  color: #FF0000;
  font-weight: bold;
}

.table .flagged > td {
  background: yellow;
}

.sustainability-table td {
  width: 25%;
}

.sustainability-table .overtime {
  background: #FF0000;
}

.sustainability-table .on-track {
  background: #00FF00;
}

.details {
  display: none;
}

.summary {
  text-decoration: underline dotted;
}

.summary:hover + .details, .details:hover {
  display: block;
  text-wrap: wrap;
}

.details dl {
  position: absolute;
  padding: 5px 5px 0px 5px;
  margin-top: 5px;
  border: solid 2px #ddd;
  background: #fff;
  text-align: left;

  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 10px;
}

dt, dd {
  line-height: 18px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 15px;
}
